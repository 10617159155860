.dcLogin {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
@media (max-width: 767.98px) {
.dcLogin {
    padding-top: 2rem;
}
}
.dcLogin ul {
  display: block;
  margin-top: 1rem;
}
.dcLogin li {
  list-style: disc;
  margin-top: 1rem;
}
.dcLogin .loginBlockUp {
  margin: auto;
  padding-bottom: 2rem;
  max-width: 420px;
}
.dcLogin .checkboxes {
  margin: auto;
  max-width: 320px;
}
.dcLogin .loginBlockLogin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
}
.dcLogin .loginBlockLogin .loginDc {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.dcLogin .loginBlockLogin .loginButton {
  display: block;
  min-width: 100%;
}
.dcLogin .loginBlockLogin button {
  display: block;
  width: 100%;
}
.dcLogin .titleBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.dcLogin .titleBlock .titleIcon {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}
.dcLogin .titleBlock .titleIcon svg {
  width: 100%;
  height: auto;
}
.dcLogin .titleBlock .titleIcon svg * {
  fill: #333333;
}
.dcLogin .titleBlock .titleText {
  color: #333333;
  text-align: left;
  margin-bottom: 1rem;
}